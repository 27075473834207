var styles = {
  "Checkbox": "Polaris-Checkbox",
  "Input": "Polaris-Checkbox__Input",
  "Backdrop": "Polaris-Checkbox__Backdrop",
  "Input-indeterminate": "Polaris-Checkbox__Input--indeterminate",
  "Icon": "Polaris-Checkbox__Icon",
  "hover": "Polaris-Checkbox--hover",
  "error": "Polaris-Checkbox--error"
};

export { styles as default };
