var styles = {
  "Navigation": "Polaris-Navigation",
  "UserMenu": "Polaris-Navigation__UserMenu",
  "ContextControl": "Polaris-Navigation__ContextControl",
  "PrimaryNavigation": "Polaris-Navigation__PrimaryNavigation",
  "LogoContainer": "Polaris-Navigation__LogoContainer",
  "hasLogoSuffix": "Polaris-Navigation--hasLogoSuffix",
  "Logo": "Polaris-Navigation__Logo",
  "LogoLink": "Polaris-Navigation__LogoLink",
  "Item": "Polaris-Navigation__Item",
  "Icon-resized": "Polaris-Navigation__Icon--resized",
  "ItemInnerWrapper": "Polaris-Navigation__ItemInnerWrapper",
  "Item-selected": "Polaris-Navigation__Item--selected",
  "Item-disabled": "Polaris-Navigation__Item--disabled",
  "Icon": "Polaris-Navigation__Icon",
  "Badge": "Polaris-Navigation__Badge",
  "ListItem-hasAction": "Polaris-Navigation__ListItem--hasAction",
  "subNavigationActive": "Polaris-Navigation--subNavigationActive",
  "Item-child-active": "Polaris-Navigation--itemChildActive",
  "ListItem": "Polaris-Navigation__ListItem",
  "RollupSection": "Polaris-Navigation__RollupSection",
  "SecondaryNavigation": "Polaris-Navigation__SecondaryNavigation",
  "ItemWrapper": "Polaris-Navigation__ItemWrapper",
  "ItemInnerDisabled": "Polaris-Navigation__ItemInnerDisabled",
  "ItemInnerWrapper-selected": "Polaris-Navigation__ItemInnerWrapper--selected",
  "Text": "Polaris-Navigation__Text",
  "Text-truncated": "Polaris-Navigation__Text--truncated",
  "SecondaryActions": "Polaris-Navigation__SecondaryActions",
  "ItemWithFloatingActions": "Polaris-Navigation__ItemWithFloatingActions",
  "ItemInnerWrapper-display-actions-on-hover": "Polaris-Navigation--itemInnerWrapperDisplayActionsOnHover",
  "SecondaryAction": "Polaris-Navigation__SecondaryAction",
  "List": "Polaris-Navigation__List",
  "SecondaryNavigation-noIcon": "Polaris-Navigation__SecondaryNavigation--noIcon",
  "Section": "Polaris-Navigation__Section",
  "Section-fill": "Polaris-Navigation__Section--fill",
  "Section-withSeparator": "Polaris-Navigation__Section--withSeparator",
  "SectionHeading": "Polaris-Navigation__SectionHeading",
  "Action": "Polaris-Navigation__Action",
  "RollupToggle": "Polaris-Navigation__RollupToggle",
  "Indicator": "Polaris-Navigation__Indicator"
};

export { styles as default };
