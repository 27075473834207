import CircularProgress from './circularProgress';
import CircularProgressBase from './circularProgressBase';
import type {
  DashedStrokeConfigType,
  StrokeColorConfigType,
  ProgressRef,
} from './types';

export default CircularProgress;
export { CircularProgressBase };
export { CircularProgressBase as CircularProgressWithChild };
export type { DashedStrokeConfigType, StrokeColorConfigType, ProgressRef };
