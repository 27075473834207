var styles = {
  "MediaCard": "Polaris-MediaCard",
  "portrait": "Polaris-MediaCard--portrait",
  "MediaContainer": "Polaris-MediaCard__MediaContainer",
  "sizeSmall": "Polaris-MediaCard--sizeSmall",
  "InfoContainer": "Polaris-MediaCard__InfoContainer",
  "Heading": "Polaris-MediaCard__Heading",
  "PrimaryAction": "Polaris-MediaCard__PrimaryAction",
  "SecondaryAction": "Polaris-MediaCard__SecondaryAction",
  "ActionContainer": "Polaris-MediaCard__ActionContainer"
};

export { styles as default };
