var styles = {
  "Outer": "Polaris-AlphaTabs__Outer",
  "Wrapper": "Polaris-AlphaTabs__Wrapper",
  "WrapperWithNewButton": "Polaris-AlphaTabs__WrapperWithNewButton",
  "ButtonWrapper": "Polaris-AlphaTabs__ButtonWrapper",
  "AlphaTabs": "Polaris-AlphaTabs",
  "Tab": "Polaris-AlphaTabs__Tab",
  "Tab-active": "Polaris-AlphaTabs__Tab--active",
  "Tab-hasActions": "Polaris-AlphaTabs__Tab--hasActions",
  "Tab-iconOnly": "Polaris-AlphaTabs__Tab--iconOnly",
  "fillSpace": "Polaris-AlphaTabs--fillSpace",
  "TabContainer": "Polaris-AlphaTabs__TabContainer",
  "fitted": "Polaris-AlphaTabs--fitted",
  "titleWithIcon": "Polaris-AlphaTabs--titleWithIcon",
  "List": "Polaris-AlphaTabs__List",
  "Item": "Polaris-AlphaTabs__Item",
  "DisclosureTab": "Polaris-AlphaTabs__DisclosureTab",
  "DisclosureTab-visible": "Polaris-AlphaTabs__DisclosureTab--visible",
  "DisclosureActivator": "Polaris-AlphaTabs__DisclosureActivator",
  "TabsMeasurer": "Polaris-AlphaTabs__TabsMeasurer",
  "NewTab": "Polaris-AlphaTabs__NewTab",
  "ActionListWrap": "Polaris-AlphaTabs__ActionListWrap",
  "Panel": "Polaris-AlphaTabs__Panel",
  "Panel-hidden": "Polaris-AlphaTabs__Panel--hidden"
};

export { styles as default };
