var styles = {
  "LegacyCard": "Polaris-LegacyCard",
  "subdued": "Polaris-LegacyCard--subdued",
  "Section-hideOnPrint": "Polaris-LegacyCard__Section--hideOnPrint",
  "hideOnPrint": "Polaris-LegacyCard--hideOnPrint",
  "Header": "Polaris-LegacyCard__Header",
  "Section": "Polaris-LegacyCard__Section",
  "Section-fullWidth": "Polaris-LegacyCard__Section--fullWidth",
  "Section-flush": "Polaris-LegacyCard__Section--flush",
  "Section-subdued": "Polaris-LegacyCard__Section--subdued",
  "SectionHeader": "Polaris-LegacyCard__SectionHeader",
  "Subsection": "Polaris-LegacyCard__Subsection",
  "Footer": "Polaris-LegacyCard__Footer",
  "LeftJustified": "Polaris-LegacyCard__LeftJustified"
};

export { styles as default };
